import React, { memo } from 'react'
import styled, { css } from 'styled-components'
import { useLocation } from 'react-router-dom'
import { Logo, Menu, Title } from './Default'

import indexpng from '../images/header-index.png'
import indexwidepng from '../images/header-index-wide.png'
import productpng from '../images/header-product.png'
import servicepng from '../images/header-service.png'
import solutionpng from '../images/header-solution.png'
import aboutpng from '../images/header-about.png'
import contactspng from '../images/header-contacts.png'

const Header = memo(() => {
  const { WRAP } = header
  const location = useLocation()

  return (
    <WRAP
      pathname={location.pathname}
    >
      <div>
        <Logo />
        <Menu type={'button'} />
        <Menu />
      </div>
      <div>
        <Title pathname={location.pathname} />
      </div>
    </WRAP>
  )
})

export default Header

const header = {
  WRAP: styled.header`
    display: flex; flex-direction: column;
    align-items: center;
    width: 100%; height: 466px;
    padding: 25px;
    ${props => props.pathname === '/' ? `
      background-image: linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ), url(${indexpng});
    ` : props.pathname === '/product' ? `
      background-image: linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ), url(${productpng});
    ` : props.pathname === '/service' ? `
      background-image: linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ), url(${servicepng});
    ` : props.pathname === '/solution' ? `
      background-image: linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ), url(${solutionpng});
    ` : props.pathname === '/about' ? `
      background-image: linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ), url(${aboutpng});
    ` : props.pathname === '/contacts' ? `
      background-image: linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ), url(${contactspng});
    ` : `
    `}
    ${props => props.pathname === '/' && css`
      @media (min-width: 2561px) {
        background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${indexwidepng});
      }
    `}
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    @media (min-width: 1024px) {
      height: 870px;
      padding: 0;
    }
    &>div:nth-child(1) {
      display: flex; justify-content: space-between; align-items: center;
      flex: 0;
      width: 100%; height: 120px;
      @media (min-width: 1024px) {
        width: 997px;
        margin: 50px 0px 0px;
      }
    }
    &>div:nth-child(2) {
      display: flex;
      flex: 1;
      width: 100%;
      padding: 30px 0px;
      @media (min-width: 1024px) {
        width: 997px;
        padding: 30px 0px 160px;
      }
    }

    /* ${props => props.theme.media`
      height: 874px;
      &>div:nth-child(1) {
        width: 1024px;
        .hedy-menu-button {
          display: none;
        }
      }
      &>div:nth-child(2) {
        width: 1024px;
        padding: 30px 0px 115px;
      }
      h1 {

      }
      nav {
        flex: 1 !important;
      }
    `} */
  `
}