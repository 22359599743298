/* eslint-disable react/jsx-pascal-case */
import React, { memo, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import main_index_page_bg2 from '../images/main-index-page-bg2.png'
import main_index_page_bg4 from '../images/main-index-page-bg4.png'
import main_index_page_bg6 from '../images/main-index-page-bg6.png'

import pdf from '../pdf/HEDY.pdf';
import download_icon from '../images/download-icon.png'
import main_index_product from '../images/main-index-product.png'
import main_index_tree from '../images/main-index-tree.png'
import main_index_rightarrow_black from '../images/main-index-rightarrow-black.png'

import service_process1 from '../images/service-process-1.png'
import service_process2 from '../images/service-process-2.png'
import service_process3 from '../images/service-process-3.png'
import service_process4 from '../images/service-process-4.png'
import service_process5 from '../images/service-process-5.png'

import service_process_list_1 from '../images/service-process-list-1.png'

import service_business_head_1 from '../images/service-business-head-1.png'
import service_business_head_2 from '../images/service-business-head-2.png'
import service_business_head_3 from '../images/service-business-head-3.png'

import service_business_body_1_1 from '../images/service-business-body-1-1.png'
import service_business_body_1_2 from '../images/service-business-body-1-2.png'
import service_business_body_1_3 from '../images/service-business-body-1-3.png'
import service_business_body_2_1 from '../images/service-business-body-2-1.png'
import service_business_body_2_2 from '../images/service-business-body-2-2.png'
import service_business_body_2_3 from '../images/service-business-body-2-3.png'
import service_business_body_3_1 from '../images/service-business-body-3-1.png'
import service_business_body_3_2 from '../images/service-business-body-3-2.png'
import service_business_body_3_3 from '../images/service-business-body-3-3.png'

import solution_as from '../images/main-index-solution-as.png';
import solution_fc from '../images/main-index-solution-fc.png';
import solution_pd from '../images/main-index-solution-pd.png';
import solution_ls from '../images/main-index-solution-ls.png';
import solution_hc from '../images/main-index-solution-hc.png';
import solution_tm from '../images/main-index-solution-tm.png';

import contacts_pdf from '../pdf/HEDY.pdf'
import contacts_mail from '../images/contacts-mail.png'
import contacts_arrow from '../images/contacts-arrow.png'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

import Product from '../pages/product/Product'
import Solution from '../pages/solution/Solution';
import About from '../pages/about/About'

const Main = memo(({ type }) => {
  useEffect(() => window.scrollTo(0, 0), []);
  if (!type) return <>Not Found...</>
  switch (type) {
    case 'index':
      return <Index />
    case 'product':
      return <Product />
    case 'service':
      return <Service />
    case 'solution':
      return <Solution.index.component />;
    case 'about':
      return <About.index.component />
    case 'contacts':
      return <Contacts />
    default:
      break;
  }
})

export default Main

const Index = memo(() => {
  const { index: WRAP } = CSS
  const { pathname } = useNavigate()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return (
    <WRAP>
      <div className={'index-page-1'}>
        <div className={'page-container'}>
          <div>
            <h1>
              HRADAR
              <svg width="34" height="33" viewBox="0 0 34 33">
                <g stroke="#207FFF" strokeWidth="7" fill="none" fillRule="evenodd" strokeLinecap="round">
                    <path d="M4 15.5h20M5 28.5l9.321.5M4 3.5h26"/>
                </g>
              </svg>
            </h1>
            <h2>
              <span>60GHz FMCW</span>
              &nbsp;RADAR SENSOR
            </h2>
            <p>
              {'60GHz 주파수 영역을 이용해\n직경10mm를 감지하는 RADAR  모듈로\n고해상도로 장애물 감지가 가능'}
            </p>
            <Link to={'/product#introduce'}>
              Learn more
            </Link>
          </div>
          <div className={'index-drone'}>
            <img src={main_index_product} alt="product" />
          </div>
        </div>
      </div>
      <div className={'index-page-2'}>
        <div className={'page-container'}>
          <h1>HEDY's Community</h1>
          <p>기술에 관한 영감을 공유합니다.</p>
          <a href={'https://cafe.naver.com/androiddaq'} target={'_blank'} rel={"noopener noreferrer"}>
            네이버 헤디 카페 바로가기
          </a>
        </div>
      </div>
      <div className={'index-page-5'}>
        <div name="a">
          차별화된&nbsp;
          <span>헤디</span>
          만의 솔루션
        </div>
        <div name="b">
          SOLUTION
        </div>
        <div name="c">
          {'지속적인 연구개발과 뛰어난 기술력을\n바탕으로 차별화된 솔루션을 제공합니다.'}
        </div>
        <div name="d">
          <Swiper
            slidesPerView={'auto'}
            spaceBetween={8}
            className="main-page-swiper"
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
          >
            {data.solution.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <Link to={`/solution#${item.link}`}>
                    <img src={item.src.main[0]} alt={item.alt} />
                    <div className={'solution-img-info'}>
                      <p>{item.alt}</p>
                      <h3>{item.title}</h3>
                    </div>
                  </Link>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        <div name="e">
          <Link to={'/solution'}>
            <img src={main_index_rightarrow_black} alt="solution 바로가기" />
          </Link>
        </div>
      </div>
      <div className={'index-page-6'}>
        <div name="a">
          “WE CAN MAKE YOUR DREAM COME TRUE”
        </div>
        <div name="b">
          {'지속적인 레이더 관련 비즈니스 발굴과 실증을 통해\n기술 고도화를 진행하고 있습니다.'}
        </div>
        <div name="c">
          <a href={pdf} download={'HEDY-회사소개서.pdf'}>
            회사소개서 다운로드
            <img src={download_icon} alt="download" />
          </a>
        </div>
      </div>
    </WRAP>
  )
})
const Service = memo(() => {
  const { service: WRAP } = CSS
  useEffect(() => window.scrollTo(0, 0), []);
  const list = {
    a: [
      { id: 0, title: '개발의뢰', text: '개발에 관해 문의 주시면 상담을 통해 요구사항을 정리합니다.', image: service_process1, },
      { id: 1, title: '요구사항 정의', text: '고객의 요구사항 분석을 통해 프로젝트의 요구사항을 정의합니다.', image: service_process2, },
      { id: 2, title: '기술평가', text: '정의된 요구사항의 예비 타당성 검토를 실시합니다.', image: service_process3, },
      { id: 3, title: '개발', text: '정의된 요구사항에 맞춰 제품 개발을 진행합니다.', image: service_process4, },
      { id: 4, title: '실증', text: '개발된 시스템이 요구사항에 만족하는지 검사를 통해 확인하고 리포팅합니다.', image: service_process5, },
    ],
    b: [
      { id: 0, head: { title: '장애물 인식 센서', text: 'FMCW를 이용한 레이더 센서를 이용하여 드론 비행 시 장애물을 인지하여 드론의 안전 주행능력을 높일 수 있습니다.', image: service_business_head_1 }, body: [
        { image: service_business_body_1_1, title: '레이더 센서' },
        { image: service_business_body_1_2, title: '장애물인지' },
        { image: service_business_body_1_3, title: '안전주행' },
      ] },
      { id: 1, head: { title: '지상관제시스템', text: '드론의 다양한 목적에 맞는 지상관제시스템을 제공합니다.', image: service_business_head_2 }, body: [
        { image: service_business_body_2_1, title: '지형맵핑' },
        { image: service_business_body_2_2, title: '방제' },
        { image: service_business_body_2_3, title: '시설물 관리' },
      ] },
      { id: 2, head: { title: '비행제어컴퓨터', text: '드론의 고도화된 임무 및 고객의 요구사항을 수행하기 위한 Pixhawk기반의 맞춤형 비행제어 컴퓨터 개발', image: service_business_head_3 }, body: [
        { image: service_business_body_3_1, title: '회로설계' },
        { image: service_business_body_3_2, title: '제작' },
        { image: service_business_body_3_3, title: 'Firmware' },
      ] },
    ]
  }
  return (
    <WRAP>
      <div className={'service-page-1'}>
        <div className={'page-container'}>
          <ul>
            <li>
              <h1>Process</h1>
            </li>
          {list.a.map((a, adex) => 
            <li key={`list${adex}`}>
              <h2>
                <span>{a.id + 1}.</span>
                <img src={a.image} alt={''} />
                <p>{a.title}</p>
              </h2>
              <p>{a.text}</p>
            </li>
          )}
          </ul>
          <div className={'list-image'}>
            <img src={service_process_list_1} alt={'list'} />
          </div>
        </div>
      </div>
    </WRAP>
  )
})
const Contacts = memo(() => {
  const { contacts: WRAP } = CSS;
  useEffect(() => window.scrollTo(0, 0), []);
  const list = [
    { id: 0, title: '본사', title_image: null, title_text: null, address: '경상남도 창원시 마산회원구 3.15대로 732, 4144호\n732, 3·15-daero, Masanhoewon-gu, Changwon-si, Republic of Korea', tel: '051-991-0806', link: '네이버 지도', link_address: 'https://naver.me/xeAcSzVh', link_image: null },
    { id: 0, title: '부설연구소', title_image: null, title_text: null, address: '부산광역시 강서구 명지국제2로 28번길 23 네오웨스턴스퀘어 2동 308호\n2-308, 23, Myeongjigukje 2-ro 28beon-gil, Gangseo-gu, Busan, Republic of Korea', tel: '051-991-0806', link: '네이버 지도', link_address: 'https://naver.me/GUwtiBR0', link_image: null },
    { id: 0, title: 'hedy@hedy.kr', title_image: contacts_mail, title_text: null, address: null, tel: null, link: null, link_address: null, link_image: null },
    { id: 0, title: 'Community', title_image: null, title_text: '고객과 함께 소통하며 성장하겠습니다.', address: null, tel: null, link: '네이버 카페 바로가기', link_address: 'https://cafe.naver.com/androiddaq', link_image: contacts_arrow, link_image_w: '24px', link_image_h: '24px', link2: 'Youtube 채널 바로가기', link2_address: 'https://www.youtube.com/channel/UCPgzpxmHAxAveKRPAq7E-zg' },
  ]
  return (
    <WRAP>
      <div className='contacts-page-1'>
        <div className='page-container'>
          <div>
            <button>
              <a href={contacts_pdf} download={'HEDY-회사소개서.pdf'}>회사소개서 다운로드</a>
            </button>
          </div>
          <ul>
          {list.map((l, ldex) => 
            <li key={`contacts${ldex}`}>
              {l.title_image && <img className={'title-image'} src={l.title_image} alt={l.title} />}
              {l.title && <h1>{l.title}</h1>}
              {l.title_text && <p>{l.title_text}</p>}
              {l.address && <address>{l.address}</address>}
              {l.tel && <span>{l.tel}</span>}
              {l.link && <div className='link-box'>
                <a href={l.link_address} target="_blank" rel="noopener noreferrer">
                  {l.link}
                  {l.link_image && <img className={'link-image'} src={l.link_image} alt={l.link} width={l.link_image_w} height={l.link_image_h} />}
                </a>
              </div>}
            </li>
          )}
          </ul>
        </div>
      </div>
    </WRAP>
  )
})

const CSS = {
  index: styled.section`
    .page-container {
      padding: 60px 25px;
      * {
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }
      ${props => props.theme.media`
        width: 997px;
        padding: 120px 0px 50px 0px;
        margin: auto;
      `}
    }
    .index-page-1 {
      .page-container {
        @media (min-width: 1024px) {
          display: flex;
          justify-content: space-between;
          height: 684px;
          padding: 115px 0px;
        }
        &>div {
          display: flex; flex-direction: column;
          justify-content: center; align-items: center;
          @media (min-width: 1024px) {
            justify-content: flex-start; align-items: start;
            padding: 28px 0px 0px;
          }
          * + * {
            margin-top: 10px;
          }
          h1 {
            display: flex; align-items: center;
            font-size: 36px; font-weight: 700;
            font-family: 'Poppins', sans-serif;
            color: #223284;
            svg {
              width: 30px; height: 30px;
              margin-left: 15px;
            }
            @media (min-width: 1024px) {
              font-size: 48px;
              svg { width: 35px; height: 35px; }
            }
          }
          h2 {
            font-family: 'Poppins', sans-serif;
            font-weight: bold;
            font-size: 16px; font-weight: 500;
            letter-spacing: 0px;
            color: #223284;
            &>span {
              font: inherit;
              font-weight: 700;
            }
            @media (min-width: 1024px) {
              font-size: 28px;
            }
          }
          p {
            width: 280px;
            font-size: 14px; font-weight: 400;
            font-family: 'Noto Sans KR', sans-serif;
            line-height: 1.625; text-align: center;
            word-break: keep-all; white-space: pre-wrap;
            color: #2d2d2d80;
            margin: 20px 0px;
            @media (min-width: 1024px) {
              font-size: 16px; text-align: start;
            }
          }
          a {
            display: flex; justify-content: center; align-items: center;
            width: 155px; height: 48px;
            font-size: 16px; font-weight: 500;
            font-family: 'Poppins', sans-serif;
            color: #ffffff;
            background-color: rgb(32 127 255);
            &:hover { font-weight: 600; }
            @media (min-width: 1024px) {
              width: 165px; height: 50px;
              margin: 60px 0px 0px;
            }
          }
        }
        .index-drone {
          flex: 0 auto;
          width: 100%; max-height: 452px;
          margin-top: 50px;
          @media (min-width: 1024px) {
            width: 434px; height: 452px;
            padding: 0; margin: 0;
          }
          img {
            width: 100%; height: 100%;
            max-width: 452px; max-height: 452px;
          }
        }
      }
    }

    .index-page-2 {
      background-image: url(${main_index_page_bg2});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      .page-container {
        display: flex; flex-direction: column;
        align-items: center; justify-content: center;
        height: 300px;
        padding: 0px;
        @media (min-width: 1024px) {
          height: 380px;
          padding: 20px 0px 0px;
        }
      }
      &>div {
        text-align: center;
        * + * {
          margin: 10px 0px;
        }
        * {
          color: #ffffff;
        }
        h1 {
          font-size: 24px; font-weight: bold;
          font-family: 'Poppins', sans-serif;
          @media (min-width: 1024px) {
            font-size: 52px;
            margin: 10px 0px;
          }
        }
        p {
          font-size: 12px; font-weight: 500;
          font-family: 'Noto Sans KR', sans-serif;
          margin: 10px 0px 20px;
          @media (min-width: 1024px) {
            font-size: 20px;
            margin: 15px 0px 50px;
          }
        }
        a {
          display: inline-flex; justify-content: center; align-items: center;
          width: 273px; height: 51px;
          font-size: 16px; font-weight: 400;
          font-family: 'Noto Sans KR', sans-serif;
          background-color: rgb(9 203 36);
          &:hover { font-weight: 600; }
          @media (min-width: 1024px) {

          }
        }
      }
    }

    .index-page-3 {
      &>div {
        height: 100%;
        &>div {
          display: flex; justify-content: space-between; align-items: center;
          &>div {
            span {
              font-size: 24px;
              font-family: 'Noto Sans KR', sans-serif;
              ${props => props.theme.media`font-size: 32px;`}
            }
            h1 {
              font-size: 44px; font-weight: bold;
              font-family: 'Poppins', sans-serif;
              margin: 5px 0px;
              ${props => props.theme.media`font-size: 52px;`}
            }
            p {
              font-size: 16px;
              font-family: 'Noto Sans KR', sans-serif;
            }
          }
          a>img {
            width: 48px; height: 40px;
            ${props => props.theme.media`width: 63px; height: 52px;`}
          }
        }
        &>ul {
          display: flex; flex-wrap: wrap;
          justify-content: space-around;
          padding: 30px 0px;
          li {
            margin-top: 20px;
            width: 222px; height: 360px;
            ${props => props.theme.media`width: 272px; height: 435px;`}
            div {
              display: flex; flex-direction: column;
              align-items: center;
              border-top: 2px solid #000;
              border-bottom: 2px solid #000;
              span {
                font-size: 24px; font-weight: bold;
                font-family: 'Noto Sans KR', sans-serif;
                padding: 30px 0px;
                ${props => props.theme.media`font-size: 32px; padding: 30px 0px 50px 0px;`}
              }
              img {
                height: 128px;
                margin: 5px 0px 20px 0px;
                ${props => props.theme.media`margin: 10px 0px 40px 0px;`}
              }
            }
            p {
              display: flex; justify-content: center; align-items: center;
              height: calc(360px - 245px);
              font-size: 16px; font-weight: 300;
              font-family: 'Noto Sans KR', sans-serif;
              text-align: center; line-height: 1.3;
              word-break: keep-all;
              margin: 10px 0px;
              ${props => props.theme.media`
                height: calc(435px - 300px);
                font-size: 20px;
              `}
            }
          }
        }
      }
    }

    .index-page-4 {
      width: 100%; height: 648px;
      background-image: url(${main_index_page_bg4});
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;
      ${props => props.theme.media`height: 747px;`}
      &>div {
        position: relative;
        height: 100%;
        ${props => props.theme.media`padding: 30px 0px;`}
        .index-tree {
          position: absolute; bottom: 60px; left: 60px;
          width: 321px; height: 440px;
          background-image: url(${main_index_tree});
          ${props => props.theme.media`bottom: 120px;`}
        }
        &>div:nth-child(2) {
          position: relative;
          z-index: 10;
          text-align: right;
          padding: 50px 0px;
          * {
            color: #ffffff;
          }
          span {
            font-size: 24px;
            font-family: 'Noto Sans KR', sans-serif;
            line-height: 1.5;
            ${props => props.theme.media`font-size: 32px;`}
          }
          h1 {
            font-size: 44px; font-weight: bold;
            font-family: 'Poppins', sans-serif;
            line-height: 1.5;
            ${props => props.theme.media`font-size: 52px;`}
          }
          p {
            font-size: 16px;
            font-family: 'Noto Sans KR', sans-serif;
          }
        }
        a {
          position: absolute; bottom: 60px; right: 100px;
          display: flex; justify-content: center; align-items: center;
          font-size: 28px; font-weight: bold;
          font-family: 'Noto Sans KR', sans-serif;
          color: #ffffff;
          ${props => props.theme.media`bottom: 120px;`}
          img {
            width: 28px;
            margin: 2px 0px 0px 5px;
            ${props => props.theme.media`width: 40px; margin: 2px 0px 0px 10px;`}
          }
          ${props => props.theme.media`right: 0px; font-size: 40px;`}
        }
      }
    }

    .index-page-5 {
      display: grid;
      grid-template-areas:
        '.'
        'a'
        'b'
        'c'
        'e'
        'd'
        '.'
      ;
      grid-template-columns: 100%;
      grid-template-rows: 50px 25px 50px 40px 53px auto 70px;
      width: 100%; height: 505px;
      @media (min-width: 1024px) {
        grid-template-areas:
          '. e .'
          '. a .'
          '. b .'
          '. c .'
          '. d .'
          '. . .'
        ;
        grid-template-columns: auto 1024px auto;
        grid-template-rows: 115px 45px 60px 45px auto 113px;
        height: 900px;
      }
      &>[name="a"] {
        grid-area: a;
        align-self: center; justify-self: center;
        color: #2d2d2d;
        font-family: 'Noto Sans KR', sans-serif;
        font-size: 16px; font-weight: 500;
        letter-spacing: 0px;
        &>span { color: #207FFF; font: inherit; }
        @media (min-width: 1024px) {
          align-self: start; justify-self: start;
          font-size: 32px;
        }
      }
      &>[name="b"] {
        grid-area: b;
        align-self: center; justify-self: center;
        color: rgba(45,45,45,1);
        font-family: "Poppins", sans-serif;
        font-size: 36px; font-weight: 700;
        letter-spacing: 0px;
        @media (min-width: 1024px) {
          align-self: end; justify-self: start;
          font-size: 52px;
        }
      }
      &>[name="c"] {
        grid-area: c;
        align-self: end; justify-self: center;
        opacity: 0.6;
        color: rgba(45,45,45,1);
        font-family: 'Noto Sans KR', sans-serif;
        font-size: 14px; font-weight: 400;
        text-align: center;
        letter-spacing: 0px; line-height: 1.6;
        white-space: pre-wrap;
        @media (min-width: 1024px) {
          align-self: end; justify-self: start;
          font-size: 16px;
          text-align: start;
          white-space: initial;
        }
      }
      &>[name="d"] {
        grid-area: d;
        .swiper {
          width: 100%; height: 100%;
          padding: 30px;
          @media (min-width: 1024px) {
            padding: 50px 0px 40px;
          }
        }
        .swiper-slide {
          position: relative;
          display: flex;
          justify-content: center; align-items: center;
          width: 187px; height: 187px;
          @media (min-width: 1024px) {
            width: 469px; height: 469px;
            margin: 0px 15px 0px 0px !important;
          }
          img {
            width: 100%; height: 100%;
          }
          div.solution-img-info {
            position: absolute;
            left: 0; bottom: 0;
            display: flex; flex-direction: column;
            justify-content: space-between;
            width: 100%; height: 70px;
            padding: 17px 20px;
            background-color: #ffffffcc;
            &>* { font-size: 12px; font-weight: 400; color: #000; }
            &>p { font-weight: 700; }
            @media (min-width: 1024px) {
              bottom: 50px;
              width: 250px; height: 80px;
              padding: 17px 0px 17px 28px;
              &>* { font-size: 18px; }
            }
          }
          &:hover {
            transform: scale(1.1); z-index: 1;
            cursor: pointer;
            @media (min-width: 1024px) {
              transform: scale(1.05);
            }
          }
        }
        @media (min-width: 1024px) {
          .swiper-pagination {

          }
        }
      }
      &>[name="e"] {
        grid-area: e;
        z-index: 1;
        align-self: center; justify-self: center;

        display: flex;
        justify-content: center; align-items: center;
        width: 100%; height: 100%;
        @media (min-width: 1024px) {
          position: relative;
        }
        &>a {
          display: flex;
          justify-content: center; align-items: end;
          width: 55px; height: 100%;
          padding: 0px 0px 5px;
          img {
            width: 35px; height: 30px;
          }
          &:hover { cursor: pointer; }
          @media (min-width: 1024px) {
            position: absolute; right: 0; top: 165px;
            width: 70px; height: 60px;
            img {
              width: 100%; height: 100%;
            }
          }
        }
      }
    }

    .index-page-6 {
      background-image: url(${main_index_page_bg6});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

      display: grid;
      grid-template-areas:
        '.'
        'a'
        'b'
        'c'
        '.'
      ;
      grid-template-columns: 100%;
      grid-template-rows: 95px 38px 52px 81px auto;

      width: 100%; height: 360px;
      @media (min-width: 1024px) {
        grid-template-areas:
        '. . .'
        '. a .'
        '. b .'
        '. c .'
        '. . .'
        ;
        grid-template-columns: auto 1024px auto;
        grid-template-rows: 130px 50px auto 52px 124px;
        height: 520px;
      }
      &>[name="a"] {
        grid-area: a;
        align-self: center;
        color: #ffffff;
        font-size: 14px; font-weight: 500;
        letter-spacing: 0px;
        text-align: center;
        @media (min-width: 1024px) {
          font-size: 19px;
        }
      }
      &>[name="b"] {
        grid-area: b;
        justify-self: center;
        color: #ffffff;
        font-size: 14px; font-weight: 300;
        letter-spacing: 0px;
        text-align: center;
        line-height: 26px;
        padding: 10px 0px 0px;
        word-break: keep-all;
        @media (min-width: 1024px) {
          font-size: 36px;
          line-height: 49px;
        }
      }
      &>[name="c"] {
        grid-area: c;
        justify-self: center; align-self: end;
        display: flex;
        width: 241px; height: 51px;
        @media (min-width: 1024px) {
          width: 241px; height: 100%;
        }
        &>a {
          display: flex;
          align-items: center; justify-content: center;
          width: 100%; height: 100%;
          color: #09022a;
          font-size: 16px; font-weight: 600;
          letter-spacing: 0px;
          background-color: rgba(255,255,255,1);
          &:hover { font-weight: 800; }
          @media (min-width: 1024px) {

          }
          &>img {
            transform: translate(10px, -2px);
            @media (min-width: 1024px) {
          
            }
          }
        }
      }
    }
    `,
  service: styled.section`
    .page-container {
      padding: 60px 40px;
      * {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
      ${props => props.theme.media`
        width: 1024px;
        padding: 120px 0px 50px 0px;
        margin: auto;
      `}
    }

    .service-page-1 {
      display: flex;
      padding: 0px 0px 100px;
      .list-image {
        display: none;
        ${props => props.theme.media`
          display: block;
        `}
      }
      ul {
        display: flex; flex-wrap: wrap;
        justify-content: center; 
        width: 100%;
        border-bottom: 2px solid #000;
        ${props => props.theme.media`
          display: none;
        `}
        li {
          width: 50%; min-width: 300px;
          padding: 30px 20px;
          border-top: 2px solid #000;
          /* box-shadow: 0px 2px 0px 0px #000; */
          &:nth-child(2n) {
            border-left: 2px solid #000;
          }
          &:nth-child(2n - 1) {
            box-shadow: 2px 0px 0px 0px #000;
          }
          h1 {
            font-size: 40px; font-weight: bold;
            font-family: 'Poppins', sans-serif;
          }
          h2 {
            font-family: 'Noto Sans KR', sans-serif;
            span {
              display: block;
              font-size: 23px; font-weight: bold;
            }
            img {
              display: block;
              width: 96px; height: 96px;
              margin: 40px 60px;
            }
            p {
              font-size: 24px; font-weight: 400;
              padding-bottom: 30px;
            }
          }
          &>p {
            width: 60%;
            font-size: 12px; font-weight: 400;
            font-family: 'Noto Sans KR', sans-serif;
            line-height: 1.5; word-break: keep-all;
          }
        }
      }
    }

    .service-page-2 {
      &>div>h1 {
        font-size: 40px; font-weight: bold;
        font-family: 'Poppins', sans-serif;
        text-align: center;
        padding: 50px;
      }
      &>div>ul {
        &>li {
          border-top: 2px solid #000;
          padding: 50px 0px;
          * {
            font-family: 'Noto Sans KR', sans-serif;
          }
          div {
            display: flex; flex-direction: column;
            align-items: center;
            h1 {
              font-size: 32px; font-weight: bold;
            }
            p {
              font-size: 12px;
              line-height: 1.5;
              padding: 10px 0px;
            }
            img {
              width: calc(100% / 3);
              margin: 50px 0px;
              ${props => props.theme.media`
                width: calc(100% / 2.5);
              `}
            }
          }

          &>ul {
            display: flex; justify-content: center;
            li {
              display: flex; flex-direction: column;
              align-items: center;
              padding: 20px;
              ${props => props.theme.media`
                padding: 20px 60px;
              `}
              * {
                font-family: 'Noto Sans KR', sans-serif;
              }
              img {
                width: 55px; height: 55px;
                ${props => props.theme.media`
                  width: 80px; height: 80px;
                `}
              }
              p {
                font-size: 12px;
                padding: 30px 0px 0px 0px;
                ${props => props.theme.media`
                  font-size: 16px;
                `}
              }
            }
          }
        }
      }
    }

  `,
  solution: styled.section`
    .page-container {
      padding: 60px 40px;
      * {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        font-family: 'Noto Sans KR', sans-serif;
      }
      ${props => props.theme.media`
        width: 1024px;
        padding: 120px 0px 50px 0px;
        margin: auto;
      `}
    }

    .solution_mountain {
      width: 100vw; transform: translateY(2px);
    }

    .solution-page-1 {
      &>div {
        display: flex; flex-direction: column;
        align-items: center;
        h1 {
          width: 100%;
          font-size: 16px; font-weight: bold;
          padding: 15px 0px;
          border-top: 2px solid #000;
          @media (min-width: 768px) {
            font-size: 24px;
          }
          ${props => props.theme.media`font-size: 40px;`}
        }
        .solution_list_m {
          width: 322px;
          margin: 50px 0px;
          @media (min-width: 768px) {
            display: none;
          }
          ${props => props.theme.media`display: none;`}
        }
        .solution_list_t {
          display: none;
          @media (min-width: 768px) {
            display: block;
            width: 100%;
          }
        }
        ul {
          display: flex; flex-wrap: wrap;
          border-bottom: 2px solid #000;
          li {
            display: flex; flex-direction: column;
            justify-content: space-between;
            width: 50%; height: 250px;
            padding: 10px;
            border-top: 2px solid #000;
            &:nth-child(2n) {
              border-left: 2px solid #000;
              @media (min-width: 768px) {
                border-left: none;
              }
            }
            h2 {
              font-size: 18px; font-weight: bold;
              padding: 5px;
            }
            div {
              p {
                font-size: 12px; font-weight: 300;
                line-height: 1.5;
              }
            }
          }
          @media (min-width: 768px) {
            li {
              width: 25%;
              border-right: 2px solid #000;
              &:last-child {
                border-right: none;
              }
            }
          }
          ${props => props.theme.media`
            li {
              height: 350px;
              padding: 30px 10px 10px 10px;
              h2 {
                font-size: 24px;
              }
            }
            div {
              p {
                font-size: 14px;
              }
            }
          `}
        }
      }
    }
  `,
  about: styled.section`
    .page-container {
      padding: 60px 40px;
      * {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        font-family: 'Noto Sans KR', sans-serif;
      }
      ${props => props.theme.media`
        width: 1024px;
        padding: 120px 0px 50px 0px;
        margin: auto;
      `}
    }
    .about-page-1 {
      &>div {
        padding: 20px;
        h1 {
          font-size: 24px; font-weight: bold;
          padding: 20px 0px;
          border-bottom: 2px solid #000;
        }
        div {
          padding: 10px 0px;
          * + * {
            padding-top: 10px;
          }
          p {
            font-size: 12px; font-weight: 500;
            line-height: 1.5;
          }
          img {
            width: 100%;
          }
        }
      }
      @media (min-width: 768px) {
        &>div {
          padding: 60px 40px;
          h1 {
            font-size: 40px;
            padding: 30px 0px;
          }
          div {
            display: flex; flex-wrap: wrap;
            justify-content: space-between;
            padding: 20px 0px;
            * + * {
              padding-top: 20px;
            }
            p {
              width: 100%;
              font-size: 20px; font-weight: 500;
            }
            img {
              width: 49%;
              &.about_hedy_1 {
                width: 100%;
              }
            }
          }
        }
      }
      ${props => props.theme.media`

      `}
    }
    .about-page-2 {
      &>div {
        padding: 20px;
        h1 {
          font-size: 24px; font-weight: bold;
          text-align: right;
          padding: 20px 0px;
          border-bottom: 2px solid #000;
        }
        .about-history-m {
          display: block;
          width: 90%;
          padding: 30px 0px;
          margin: auto;
        }
        .about-history-t {
          display: none;
        }
        ul {
          border-top: 2px solid #000;
          border-bottom: 2px solid #000;
          li {
            display: flex;
            width: 100%;
            padding: 15px 0px;
            border-bottom: 1px solid #000;
            &:last-child {
              border-bottom: none;
            }
            h2 {
              flex: 0 auto;
              width: 80px;
              padding-left: 15px;
              font-size: 12px; font-weight: bold;
            }
            div {
              flex: 1;
              p {
                font-size: 12px; font-weight: 400;
                line-height: 1.5;
              }
            }
          }
        }
        @media (min-width: 768px) {
          .about-history-m {
            display: none;
          }
          .about-history-t {
            display: block;
            width: 100%;
            padding: 100px 0px;
            ${props => props.theme.media`padding: 150px 0px;`}
          }
          h1 {
            font-size: 40px;
            padding: 30px 0px;
          }
          ul {
            li {
              h2 {
                width: 300px;
                font-size: 16px;
                padding-left: 80px;
              }
              div {
                p {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
    .about_building {
      width: 100vw; transform: translateY(2px);
    }
  `,
  contacts: styled.section`
    .page-container {
      padding: 60px 40px;
      * {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        font-family: 'Noto Sans KR', sans-serif;
      }
      @media (min-width: 1024px) {
        width: 1024px;
        padding: 95px 0px 150px 0px;
        margin: auto;
      }
    }
    .contacts-page-1 {
      &>div {
        * {
          font-family: 'Noto Sans KR', sans-serif;
        }
        &>div {
          display: flex; justify-content: flex-end;
          padding: 30px 0px;
          @media (min-width: 1024px) {
            padding: 30px 0px 40px;
          }
          &>button {
            width: 165px; height: 35px;
            a {
              display: flex; justify-content: center; align-items: center;
              width: 100%; height: 100%;
              font-size: 12px; font-weight: 400;
              color: #fff;
              &:hover { font-weight: 700; }
            }
            background-color: #191919;
            ${props => props.theme.media`
              width: 237px; height: 51px;
              a {
                font-size: 16px;
              }
            `}
          }
        }
        &>ul {
          display: flex; flex-wrap: wrap;
          li {
            position: relative;
            display: flex; flex-direction: column;
            width: 100%;
            padding: 20px 10px;
            border-top: 1px solid #000;
            @media (min-width: 768px) {
              padding: 40px;
            }
            &:nth-child(1) {
              width: 100%;
              @media (min-width: 768px) {
                width: 50%;
                padding: 40px 0px 20px;
              }
            }
            &:nth-child(2) {
              width: 100%;
              @media (min-width: 768px) {
                width: 50%;
                border-left: 1px solid #000;
                padding: 40px 40px 30px;
              }
            }
            .title-image {
              width: 37px; height: 30px;
              @media (min-width: 768px) {
                width: 48px; height: 36px;
                margin: 0px 5px;
              }
            }
            h1 {
              font-family: "Poppins", sans-serif;
              font-size: 32px; font-weight: 700;
              @media (min-width: 768px) {
                font-size: 35px;
              }
            }
            p {
              font-size: 16px; font-weight: 400;
              padding: 10px 0px;
              @media (min-width: 768px) {
                font-size: 35px;
              }
            }
            address {
              font-size: 12px; font-weight: 300;
              line-height: 1.3;
              white-space: pre-line;
              padding: 10px 0px;
              @media (min-width: 768px) {
                font-size: 16px;
                line-height: 26px;
                padding: 15px 0px 0px;
              }
            }
            span {
              font-size: 16px; font-weight: bold;
              padding: 20px 0px;
              @media (min-width: 768px) {
                font-size: 24px;
                padding: 15px 0px 20px;
              }
            }
            .link-box {
              padding: 80px 0px 0px 0px;
              ${props => props.theme.media`padding: 150px 0px 0px 0px;`}
              a {
                font-size: 16px; font-weight: bold;
                text-decoration: underline;
                color: #000;
                padding: 0px 10px;
                ${props => props.theme.media`font-size: 20px;`}
              }
            }
          }
          li:nth-child(1), li:nth-child(2) {
            height: 300px;
            @media (min-width: 768px) {
              height: 450px;
            }
            &>.link-box {
              position: absolute; bottom: 30px;
            }
          }
          li:nth-child(3) {
            @media (min-width: 768px) {
              padding: 30px 0px;
            }
            h1 {
              width: 100%;
              text-align: right;
              font-family: "Poppins", sans-serif;
              font-weight: 700;
              margin: 60px 0px 0px;
            }
          }
          li:nth-child(4) {
            border-bottom: 1px solid #000;
            .link-box{
              display: flex; justify-content: flex-end; align-items: center;
              @media (min-width: 768px) {
                padding: 110px 0px 0px;
              }
              a {
                display: flex; align-items: center;
                font-size: 12px; font-weight: 300;
                text-decoration: none;
                @media (min-width: 768px) {
                  font-size: 16px; font-weight: 400;
                }
                img {
                  width: 26px; height: 26px;
                  margin: 0px 0px 0px 5px;
                  @media (min-width: 768px) {
                    width: 40px; height: 40px;
                    margin: 0px 10px;
                  }
                }
              }
            }
            @media (min-width: 768px) {
              padding: 40px 0px 20px;
              &>p {
                color: #191919;
                font-family: "Noto Sans KR", sans-serif;
                font-size: 24px; font-weight: 400;
                letter-spacing: 0px;
              }
            }
          }
        }
      }
    }
  `,
}

const data = {
  solution: [
    // src: { main: [ M, D ], page [ M ,D ] }
    {
      id: 1,
      src: {
        main: [solution_as],
        page: [],
      },
      link: 'as',
      alt: 'Area Sensing(AS)',
      title: '다목적 검출 범용',
      text: [
        '감지되는 Point Cloud 정보(x,y,z,v)를 출력하며 다양한 분야에 적용할 수 있는 기본 Solution입니다.',
        'Point Cloud 정보를 획득하여 원하는 Application에 적용 가능합니다.',
      ],
    },
    {
      id: 2,
      src: {
        main: [solution_fc],
        page: [],
      },
      link: 'fc',
      alt: 'Flight Controller(FC)',
      title: '드론 충돌방지 및 회피',
      text: [
        '드론에 적용해 충돌방지 및 회피 기능 제공하는 Solution입니다.',
        'MAVLink Protocol 지원하기 때문에 Flight Controller(FC)에 연결해 즉시 사용 가능합니다.',
      ],
    },
    {
      id: 3,
      src: {
        main: [solution_pd],
        page: [],
      },
      link: 'pd',
      alt: 'People Detecting(PD)',
      title: '실외/실내 사람 감지',
      text: [
        '사람을 감지하고 추적하는 Solution입니다.',
        '감지되는 Point Cloud 정보를 기반으로 Target Object(사람) 할당 및 추적정보를 제공합니다.',
      ],
    },
    {
      id: 4,
      src: {
        main: [solution_ls],
        page: [],
      },
      link: 'ls',
      alt: 'Level Sensing(LS)',
      title: '유체/고체 레벨 측정',
      text: [
        '고체 또는 유체의 Level을 감지하는 Solution입니다.',
        '비접촉식으로 타겟의 Level정보를 1mm 정밀도로 제공합니다.',
      ],
    },
    {
      id: 5,
      src: {
        main: [solution_hc],
        page: [],
      },
      link: 'hc',
      alt: 'Health Care',
      title: '실내 사람 안전 모니터링',
      text: [
        '사람을 감지하고 호흡 및 심박수를 측정하는 Solution입니다. 실내 재실 유무 및 위치, 건강 상태를 확인할 수 있습니다.',
      ],
    },
    {
      id: 6,
      src: {
        main: [solution_tm],
        page: [],
      },
      link: 'tm',
      alt: 'Traffic Monitoring',
      title: '교통/보행 모니터링',
      text: [
        '도로 또는 인도의 차량 및 사람을 감지하는 Solution입니다. 차량 또는 사람의 통행량, 위치를 확인할 수 있습니다.',
      ],
    },
  ],
};