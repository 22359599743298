import React, { memo } from 'react'
import { Route, Routes } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import media from '../module/media'
import Footer from './components/Footer'
import Header from './components/Header'
import Main from './components/Main'

const App = memo(() => {
  return (
    <ThemeProvider theme={{ ...media }}>
      <Routes>
        <Route path={'/'} exact={true} 
          element={<>
            <Header />
            <Main type={'index'} />
            <Footer />
          </>}
        />
        <Route path={'/product'} exact={true} 
          element={<>
            <Header />
            <Main type={'product'} />
            <Footer />
          </>}
        /> 
        <Route path={'/service'} exact={true} 
          element={<>
            <Header />
            <Main type={'service'} />
            <Footer />
          </>}
        /> 
        <Route path={'/solution'} exact={true} 
          element={<>
            <Header />
            <Main type={'solution'} />
            <Footer />
          </>}
        /> 
        <Route path={'/about'} exact={true} 
          element={<>
            <Header />
            <Main type={'about'} />
            <Footer />
          </>}
        /> 
        <Route path={'/contacts'} exact={true} 
          element={<>
            <Header />
            <Main type={'contacts'} />
            <Footer />
          </>}
        />
      </Routes>
    </ThemeProvider>
  )
})

export default App