/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom'

import product_hradar from './images/product-hradar.png'
import product_hradar_spec from './images/product-hradar-specification.png'
import product_hrader_aop01 from './images/product-hrader-aop-01.png'
import product_hrader_aop02 from './images/product-hrader-aop-02.png'
import product_kc_mark from './images/product-kc-mark.png';

const Product = memo(() => {
  const { Wrap, Intro, Spec, Aop } = Css;
  const navigate = useNavigate();
  const location = useLocation();
  const introRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (location.hash === '#introduce') {
      if (introRef.current) {
        introRef.current.scrollIntoView({ behavior: 'smooth' });
        navigate(location.pathname, { replace: true });
      }
    }
  }, []);
  return (
    <Wrap>
      <Intro ref={introRef} className={'introduce'}>
        <div name="a">
          <h1>HRADAR</h1>
        </div>
        <div name="b">
          <h2>
            <span>60GHz FMCW</span>
            RADAR SENSOR
          </h2>
        </div>
        <div name="c">
          {data.a.map((a, adex) => <p key={adex}>{ a }</p>)}
        </div>
        <div name="d">
          <h1>
            HRADAR
            <svg width="34" height="33" viewBox="0 0 34 33">
              <g stroke="#207FFF" strokeWidth="7" fill="none" fillRule="evenodd" strokeLinecap="round">
                  <path d="M4 15.5h20M5 28.5l9.321.5M4 3.5h26"/>
              </g>
            </svg>
          </h1>
          <img src={product_hradar} alt={'hradar'} />
          <img src={product_kc_mark} alt={'kc_mark'}
            className="KCmark"
          />
        </div>
      </Intro>
      <Spec>
        <div name="a">
          <h1>Specification</h1>
        </div>
        <div name="b">
          <img src={product_hradar_spec} alt="HRADAR Specification" />
        </div>
        <div name="c">
          <ul>
            {data.b.map((b, bdex) => 
              <li key={bdex}>
                <label>{b.title}</label>
                <div>{b.text}</div>
              </li>
            )}
            <span>
              감지 설정, 감지하려는 타겟, 주변 환경에 따라 성능은 상이할 수 있음.
            </span>
          </ul>
        </div>
      </Spec>
      <Aop.Wrap>
        <Aop.Intro>
          <div name="a">
            <div>
              <h1>HRADAR_AoP</h1>
              <p>{'초소형 사이즈\n실내 감지용'}</p>
            </div>
          </div>
          <div name="b">
            <img src={product_hrader_aop01} alt="" />
          </div>
          <div name="c">
            <img src={product_hrader_aop02} alt="" />
          </div>
        </Aop.Intro>
        <Aop.Spec>
          <div name="a">
            <h1>Specification</h1>
          </div>
          <div name="b">
            <ul>
              {data.aop.map((b, bdex) => 
                <li key={bdex}>
                  <label>{b.title}</label>
                  <div>{b.text}</div>
                </li>
              )}
              <span>
                감지 설정, 감지하려는 타겟, 주변 환경에 따라 성능은 상이할 수 있음.
              </span>
            </ul>
          </div>
        </Aop.Spec>
      </Aop.Wrap>
    </Wrap>
  )
})
export default Product;

const Css = {
  Wrap: styled.section`
    .page-container {
      padding: 50px 40px;
      * {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
      ${props => props.theme.media`
        width: 1024px;
        padding: 120px 0px 50px 0px;
        margin: auto;
      `}
    }
    .product-page-1 {
      &>div {
        h1 {
          font-size: 30px; font-weight: bold;
          font-family: 'Poppins', sans-serif;
          color: #223284;
          margin-bottom: 10px;
          @media (min-width: 1024px) {
            font-size: 42px;
          }
        }
        h2 {
          font-size: 20px; font-weight: 500;
          font-family: 'Poppins', sans-serif;
          color: #223284;
          margin: 0px 0px 15px 0px;
          &>span {
            font: inherit;
            font-weight: 700;
            margin: 0px 5px 0px 0px;
          }
          @media (min-width: 1024px) {
            font-size: 28px;
          }
        }

        p {
          font-size: 12px; font-weight: 500;
          font-family: 'Noto Sans KR', sans-serif;
          line-height: 1.5;
          margin-bottom: 10px;
          ${props => props.theme.media`font-size: 16px;`}
        }

        .product-page-imagebox {
          display: flex; flex-wrap: wrap;
          justify-content: center; align-items: center;
          padding: 50px 0px 20px 0px;
          img:nth-child(1) {
            width: 257px; height: 293px;
            margin: 20px;
            ${props => props.theme.media`width: 363px; height: 413px;`}
          }
          img:nth-child(2) {
            width: 361px; height: 300px;
            margin: 20px;
            ${props => props.theme.media`width: 518px; height: 429px;`}
          }
        }

        .product-page-specification {
          width: 100%;
          text-align: center;
          span {
            display: block;
            width: 100%;
            font-size: 16px; font-weight: 500;
            font-family: 'Noto Sans KR', sans-serif;
            text-align: center;
            margin: 10px 0px 30px 0px;
            ${props => props.theme.media`font-size: 24px;`}
          }
          li {
            display: inline-flex;
            align-items: center;
            width: 570px; height: 23px;
            ${props => props.theme.media`width: 829px; height: 32px;`}
            @media (max-width: 650px) {
              display: flex; flex-direction: column;
              width: auto; height: auto;
              padding: 10px 0px;
            }
            label {
              width: 240px;
              font-size: 12px; font-weight: 400;
              font-family: 'Noto Sans KR', sans-serif;
              text-align: left;
              padding: 0px 20px;
              ${props => props.theme.media`width: 315px; font-size: 16px; padding: 0px 35px;`}
              @media (max-width: 650px) {
                display: block; width: 100%;
                text-align: center;
              }
            }
            div {
              font-size: 12px; font-weight: 500;
              font-family: 'Noto Sans KR', sans-serif;
              ${props => props.theme.media`font-size: 16px;`}
              @media (max-width: 650px) {
                display: block; width: 100%; text-align: left;
                text-align: center;
                padding: 10px 0px 0px 0px;
              }
            }
            &:nth-child(2n - 1) {
              background-color: rgb(240 240 240);
            }
          }
        }
      }
    }

    .product-page-2 {
      &>div {
        h1 {
          font-size: 32px; font-weight: bold;
          font-family: 'Noto Sans KR', sans-serif;
          color: rgb(34 50 132);
          text-align: center;
          padding-bottom: 40px; margin-bottom: 40px;
          border-bottom: 1px solid rgb(66 169 224);
          ${props => props.theme.media`font-size: 40px; padding-bottom: 70px; `}
        }
        ul {
          display: flex; flex-wrap: wrap;
          justify-content: space-around;
          li {
            margin: 3px;
            img {
              width: 220px; height: 364px;
              ${props => props.theme.media`width: 319px; height: 526px;`}
            }
            h2 {
              font-size: 16px; font-weight: bold;
              font-family: 'Noto Sans KR', sans-serif;
              text-align: center;
              color: rgb(34 50 132);
              padding: 15px 0px;
              ${props => props.theme.media`font-size: 24px;`}
            }
          }
        }
      }
    }
  `,
  Intro: styled.div`
    display: grid;
    grid-template-areas:
      '.'
      'a'
      'b'
      'c'
      'd'
      '.'
    ;
    grid-template-columns: 100%;
    grid-template-rows: 50px 43px 26px 156px auto 25px;
    @media (min-width: 1024px) {
      grid-template-areas:
        '. . . .'
        '. d . .'
        '. d a .'
        '. d b .'
        '. d c .'
        '. . . .'
      ;
      grid-template-columns: auto 465px 528px auto;
      grid-template-rows: 116px 134px 30px 75px 244px 34px;
    }
    &>[name="a"] {
      grid-area: a;
      align-self: center;
      padding: 0px 25px;
      @media (min-width: 1024px) {
        padding: 0px 0px 0px 35px;
      }

      h1 {
        font-size: 30px; font-weight: bold;
        font-family: 'Poppins', sans-serif;
        color: #223284;
        margin-bottom: 10px;
        @media (min-width: 1024px) {
          font-size: 42px;
        }
      }
    }
    &>[name="b"] {
      grid-area: b;
      align-self: center;
      padding: 0px 0px 0px 25px;
      @media (min-width: 1024px) {
        align-self: start;
        padding: 3px 0px 0px 35px;
      }
      h2 {
        font-size: 20px; font-weight: 500;
        font-family: 'Poppins', sans-serif;
        color: #223284;
        margin: 0px 0px 15px 0px;
        &>span {
          font: inherit;
          font-weight: 700;
          margin: 0px 5px 0px 0px;
        }
        @media (min-width: 1024px) {
          font-size: 28px;
        }
      }
    }
    &>[name="c"] {
      grid-area: c;
      padding: 0px 25px;
      @media (min-width: 1024px) {
        padding: 0px 0px 0px 35px;
      }
      p {
        font-size: 12px; font-weight: 500;
        font-family: 'Noto Sans KR', sans-serif;
        line-height: 1.5;
        margin-bottom: 10px;
        @media (min-width: 1024px) {
          font-size: 16px;
          letter-spacing: 0.2px;
          margin-bottom: 35px;
          &:nth-child(2) {
            word-break: break-all;
          }
        }
      }
    }
    &>[name="d"] {
      grid-area: d;
      position: relative;
      justify-self: center;
      width: 272px; height: 218px;
      margin: 20px 0px 0px;
      overflow: hidden;
      &>h1 {
        display: none;
        @media (min-width: 1024px) {
          position: absolute; top: 0; left: 0; z-index: 1;
          display: flex; align-items: center;
          font-size: 40px; font-weight: 700;
          font-family: 'Poppins', sans-serif;
          color: #ffffff;
          padding: 38px;
          svg {
            width: 30px; height: 30px;
            margin-left: 10px;
          }
        }
      }
      &>img {
        transform: translateY(-52px);
        width: 100%; height: 270px;
      }
      .KCmark {
        position: absolute; z-index: 1;
        transform: translateY(0);
        right: 10px; bottom: 10px;
        width: 28px; height: 44px;
      }
      @media (min-width: 1024px) {
        width: 100%; height: 100%;
        margin: 0;
        &>img {
          transform: translateY(0);
          height: 100%;
        }
        .KCmark {
          position: absolute; z-index: 1;
          right: 24px; bottom: 24px;
        }
      }
    }
  `,
  Spec: styled.div`
    display: grid;
    grid-template-areas:
      '.'
      'a'
      'b'
      'c'
      '.'
    ;
    grid-template-columns: 100%;
    grid-template-rows: 20px 25px auto auto 35px;
    padding: 0px 25px;
    @media (min-width: 1024px) {
      grid-template-areas:
        '. . .'
        '. a .'
        '. b .'
        '. c .'
        '. . .'
      ;
      grid-template-columns: auto 999px auto;
      grid-template-rows: 34px 34px 431px auto 65px;
    }
    &>[name="a"] {
      grid-area: a;
      justify-self: center;
      h1 {
        font-size: 16px; font-weight: 700;
        font-family: 'Poppins', sans-serif;
        color: #292929;
        @media (min-width: 1024px) {
          font-size: 24px;
        }
      }
    }
    &>[name="b"] {
      grid-area: b;
      justify-self: center;
      @media (min-width: 1024px) {
        display: grid;
        align-items: center;
      }
      img {
        width: 272px; height: 132px;
        margin: 30px 0px;
        @media (min-width: 1024px) {
          width: 647px; height: 314px;
          margin: 0px;
        }
      }
    }
    &>[name="c"] {
      grid-area: c;

      &>ul {
        &>li {
          display: flex;
          padding: 3px 0px;
          &:nth-child(2n-1) {
            background-color: #F0F0F0;
          }
          &>label {
            width: 110px;
            font-size: 12px; font-weight: 400;
            font-family: 'Noto Sans KR', sans-serif;
            line-height: 1.5;
            color: #292929;
            padding: 0px 0px 0px 18px;
            @media (min-width: 1024px) {
              width: 400px;
              font-size: 16px;
              padding: 0px 0px 0px 120px;
            }
          }
          &>div {
            width: calc(100% - 110px);
            font-size: 12px; font-weight: 700;
            font-family: 'Noto Sans KR', sans-serif;
            line-height: 1.5;
            color: #292929;
            @media (min-width: 1024px) {
              width: calc(100% - 400px);
              font-size: 16px;
            }
          }
        }
        &>span {
          display: flex; justify-content: end;
          width: 100%;
          font-size: 10px; font-weight: 400;
          color: #303030;
          letter-spacing: 0px;
          margin: 12px 0px 0px;
          @media (min-width: 1024px) {
            font-size: 12px;
            padding: 0px 15px;
          }
        }
      }
    }
  `,
  Aop: {
    Wrap: styled.div`
      background-color: #f3f3f3;
    `,
    Intro: styled.div`
      display: grid;
      grid-template-areas: 
        '.'
        'a'
        'b'
        'c'
        '.'
      ;
      grid-template-columns: 100%;
      grid-template-rows: 35px 160px 232px auto 35px;
      @media (min-width: 1024px) {
        grid-template-areas:
          '. . . .'
          '. a b .'
          '. c c .'
          '. . . .'
        ;
        grid-template-columns: auto 448px 549px auto;
        grid-template-rows: 66px 339px 343px 50px;
      }
      &>[name="a"] {
        grid-area: a;
        width: 100%; height: 100%;
        background-color: #18A9FD;
        &>div {
          display: flex; flex-direction: column;
          justify-content: space-around;
          height: 100%;
          padding: 0px 25px;
          @media (min-width: 1024px) {
            justify-content: space-between;
            padding: 42px 33px;
          }
          &>h1 {
            color: #ffffff;
            font-family: "Poppins", sans-serif;
            font-size: 30px; font-weight: 700;
            letter-spacing: 0px;
            @media (min-width: 1024px) {
              font-size: 40px;
            }
          }
          &>p {
            color: #303030;
            font-family: "Noto Sans KR", sans-serif;
            font-size: 12px; font-weight: 400;
            letter-spacing: 0px;
            line-height: 17px;
            white-space: pre-wrap;
            @media (min-width: 1024px) {
               font-size: 16px;
            }
          }
        }
      }
      &>[name="b"] {
        grid-area: b;
        display: flex; justify-content: center;
        background-color: #1fe063;
        &>img {
          width: 375px; height: 232px;
          @media (min-width: 1024px) {
            width: 549px; height: 100%;
          }
        }
      }
      &>[name="c"] {
        grid-area: c;
        justify-self: center;
        padding: 60px 0px 30px;
        @media (min-width: 1024px) {
          padding: 110px 0px 0px;
        }
        &>img {
          width: 272px; height: 86px;
          @media (min-width: 1024px) {
            width: 563px; height: 180px;
          }
        }
      }
    `,
    Spec: styled.div`
      display: grid;
      grid-template-areas:
        'a'
        '.'
        'b'
        '.'
      ;
      grid-template-columns: 100%;
      grid-template-rows: 25px 25px auto 35px;
      padding: 0px 25px;
      @media (min-width: 1024px) {
        grid-template-areas:
          '. a .'
          '. . .'
          '. b .'
          '. . .'
        ;
        grid-template-columns: auto 999px auto;
        grid-template-rows: 34px 34px auto 65px;
      }
      &>[name="a"] {
        grid-area: a;
        justify-self: center;
        h1 {
          font-size: 16px; font-weight: 700;
          font-family: 'Poppins', sans-serif;
          color: #292929;
          @media (min-width: 1024px) {
            font-size: 24px;
          }
        }
      }
      &>[name="b"] {
        grid-area: b;

        &>ul {
          &>li {
            display: flex;
            padding: 3px 0px;
            @media (min-width: 1024px) {
              padding: 4px 0px;
            }
            &:nth-child(2n-1) {
              background-color: #e1dede;
            }
            &>label {
              width: 110px;
              font-size: 12px; font-weight: 400;
              font-family: 'Noto Sans KR', sans-serif;
              line-height: 1.5;
              color: #292929;
              padding: 0px 0px 0px 18px;
              @media (min-width: 1024px) {
                width: 400px;
                font-size: 16px;
                padding: 0px 0px 0px 120px;
              }
            }
            &>div {
              width: calc(100% - 110px);
              font-size: 12px; font-weight: 700;
              font-family: 'Noto Sans KR', sans-serif;
              line-height: 1.5;
              color: #292929;
              @media (min-width: 1024px) {
                width: calc(100% - 400px);
                font-size: 16px;
              }
            }
          }
          &>span {
            display: flex; justify-content: end;
            width: 100%;
            font-size: 10px; font-weight: 400;
            color: #303030;
            letter-spacing: 0px;
            margin: 12px 0px 0px;
            @media (min-width: 1024px) {
              font-size: 12px;
              padding: 0px 15px;
            }
          }
        }
      }
    `,
  },
}

const data = {
  a: [
    'HRADAR는 60GHz 주파수 영역을 이용해 직경10mm 물체를 감지하는 RADAR 모듈로 고해상도 감지가 가능합니다.',
    '장애물 인식 정보를 3D 또는 4D 데이터(x y z v)로 전송하여 산업 안전, 드론 안전/자율비행, 교통/사람 모니터링 등 다양한 산업 분야에 적용 가능합니다.',
    '다양한 I/O입출력 지원을 통해 다양한 산업분야에 적용이 가능합니다.',
  ],
  b: [
    { title: '사용 주파수', text: '60~64GHz' },
    { title: '변조방식', text: 'FMCW' },
    { title: 'FoV (시야각)', text: '방위각(Azimuth) ±60°  / 고도각(Elevation) ±15°' },
    { title: '감지 거리', text: '0.5 ~ 50 m' },
    { title: '감지 속도', text: '0 ~ 20 m/s' },
    { title: '감지 데이터', text: '2D(x, y) 또는 3D(x, y, z) 감지 + 속도(Velocity)' },
    { title: '전원', text: '5V' },
    { title: '크기', text: '75 x 85 x 25 mm (Cable 450mm)' },
    { title: '무게', text: '82g' },
    { title: '사용 온도', text: '-20 ~ 85℃' },
    { title: '인증', text: 'KC' },
  ],
  aop: [
    { title: '사용 주파수', text: '60~64GHz' },
    { title: '변조방식', text: 'FMCW' },
    { title: 'FoV (시야각)', text: '방위각(Azimuth) ±60° / 고도각(Elevation) ±60°' },
    { title: '감지 거리', text: '0.5 ~ 30 m' },
    { title: '감지 속도', text: '0 ~ 20 m/s' },
    { title: '감지 데이터', text: '2D(x, y) 또는 3D(x, y, z) 감지 + 속도(Velocity)' },
    { title: '전원', text: '5V' },
    { title: '크기', text: '55 x 12 x 20 mm (지지대, 커넥터 포함)' },
    { title: '무게', text: '16.1g' },
    { title: '사용 온도', text: '-25 ~ 60℃' },
  ],
}