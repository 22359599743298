/* eslint-disable react/jsx-pascal-case */
import React, { memo, useEffect } from 'react';
import styled, { css } from 'styled-components';

import hedyLogo from './images/hedy-about.png';
import download_icon from './images/download-icon.png';
import hedyHistoryBg from './images/hedy-history-bg.png';
import hedyOffice1 from './images/hedy-info-office-1.png';
import hedyOffice2 from './images/hedy-info-office-2.png';
import hedyInfoBg from './images/hedy-info-bg.png';

import pdf from '../../pdf/HEDY.pdf';

const About = {
  index: {
    component: memo(() => {
      const { Wrap } = About.index.css;
      useEffect(() => window.scrollTo(0, 0), []);
      return (
        <Wrap>
          <About.intro.component />
          <About.history.component />
          <About.info.component />
        </Wrap>
      );
    }),
    css: {
      Wrap: styled.div``,
    },
  },
  intro: {
    component: memo(() => {
      const { Wrap, Box } = About.intro.css;
      return (
        <Wrap>
          <Box name="a">
            <h1>About<span>HEDY</span></h1>
          </Box>
          <Box name="b">
            <img src={hedyLogo} alt="HEDY logo" />
          </Box>
          <Box name="c">
            <p>Technology for intelligent radar</p>
          </Box>
          <Box name="d">
            <a href={pdf} download={'HEDY-회사소개서.pdf'}>
              회사소개서 다운로드
              <span />
            </a>
          </Box>
          <Box name="e">
            {About.data.about.map((item, index) => (
              <p key={index}>{item}</p>
            ))}
          </Box>
        </Wrap>
      );
    }),
    css: {
      Wrap: styled.div`
        display: grid;
        grid-template: 
          '. . .' 50px
          '. a .' 43px
          '. . .' 20px
          '. b .' auto
          '. . .' 25px
          '. c .' 20px
          '. . .' 20px
          '. d .' 50px
          '. . .' 20px
          '. e .' auto
          '. . .' 50px / 25px auto 25px;
        ;
        background-color: #ffffff;
        @media (min-width: 1024px) {
          grid-template:
            '. . . .' 120px
            '. a . .' 73px
            '. . . .' 25px
            '. b b .' 412px
            '. . . .' 85px
            '. c e .' 136px
            '. d e .' auto
            '. . . .' 80px / auto 366px calc(1000px - 366px) auto
          ;
        }
      `,
      Box: styled.div`
        grid-area: ${p => p.name};
        padding: 0px 25px;
        @media (min-width: 1024px) {
          padding: 0px;
        }
        ${(p) => p.name === 'a' && css`
          h1 {
            color: #191919;
            font-family: "Poppins", sans-serif;
            font-size: 30px; font-weight: 700;
            letter-spacing: 0px;
            span {
              font: inherit;
              color: #285d8f;
              margin: 0px 0px 0px 7px;
            }
            @media (min-width: 1024px) {
              font-size: 40px; font-weight: 400;
              span { font-weight: 700; }
            }
          }
        `}
        ${(p) => p.name === 'b' && css`
          img {
            width: 100%;
          }
        `}
        ${(p) => p.name === 'c' && css`
          p {
            color: #00b4f4;
            font-family: 'Noto Sans KR', sans-serif;
            font-size: 18px; font-weight: 400;
            letter-spacing: 0px;
            @media (min-width: 1024px) {
              font-size: 32px; 
              line-height: 46px;
            }
          }
        `}
        ${(p) => p.name === 'd' && css`
          a {
            display: flex;
            align-items: center; justify-content: center;
            width: 100%; height: 100%;
            color: #ffffff;
            font-family: "Noto Sans KR", sans-serif;
            font-size: 16px; font-weight: 500;
            letter-spacing: 0px;
            background-color: #00b4f4;
            &:hover { font-weight: 700; }
            @media (min-width: 1024px) {
              width: 237px; height: 51px;
            }
            span {
              width: 10px; height: 100%;
              margin: 0px 0px 0px 3px;
              background-color: #ffffff;
              mask-image: url(${download_icon});
              mask-repeat: no-repeat;
              mask-position: center center;
            }
          }
        `}
        ${(p) => p.name === 'e' && css`
          p {
            color: #191919;
            font-family: "Noto Sans KR", sans-serif;
            font-size: 12px; font-weight: 400;
            letter-spacing: 0px;
            line-height: 17px;
            &:nth-child(2) {
              margin: 20px 0px;
            }
            @media (min-width: 1024px) {
              font-size: 24px;
              line-height: 34px;
              padding: 10px 0px 0px;
            }
          }
        `}
      `,
    },
  },
  history: {
    component: memo(() => {
      const { Wrap, Box } = About.history.css;
      return (
        <Wrap>
          <Box name="a">
            <h1>History of <span>HEDY</span></h1>
          </Box>
          <Box name="b">
            <span />
          </Box>
          <Box name="c">
            <span />
            <About.history.graph.component
              year={2023} arr={About.data.history['2023']}
            />
            <About.history.graph.component
              year={2022} arr={About.data.history['2022']}
            />
            <About.history.graph.component
              year={2021} arr={About.data.history['2021']}
            />
            <About.history.graph.component
              year={2020} arr={About.data.history['2020']}
            />
            <About.history.graph.component
              year={2019} arr={About.data.history['2019']}
            />
          </Box>
          <Box name="d">
            <img src={hedyHistoryBg} alt="history HEDY" />
          </Box>
        </Wrap>
      );
    }),
    css: {
      Wrap: styled.div`
        display: grid;
        grid-template:
          '. . .' 50px
          '. a .' 43px
          '. b .' 21px
          '. . .' 41px
          '. c .' auto
          '. . .' 70px / 34px auto 34px
        ;
        background-color: #f4f7f8;
        @media (min-width: 1024px) {
          grid-template:
            '. . . . .' 60px
            '. . a . .' 72px
            '. . b . .' 40px
            '. . . . .' 75px
            '. d c c .' auto
            '. . . . .' 158px / auto 238px auto 238px auto
          ;
        }
      `,
      Box: styled.div`
        ${(p) => p.name && css`
          grid-area: ${p.name};
        `}
        ${(p) => p.name === 'a' && css`
          align-self: center; justify-self: center;
          h1 {
            color: #191919;
            font-family: "Poppins", sans-serif;
            font-size: 30px; font-weight: 700;
            letter-spacing: 0px;
            span {
              color: #285d8f;
              font: inherit;
            }
            @media (min-width: 1024px) {
              font-size: 40px;
            }
          }
        `}
        ${(p) => p.name === 'b' && css`
          display: grid;
          align-content: end; justify-content: center;
          span {
            width: 60px; height: 1px;
            background-color: #191919;
          }
        `}
        ${(p) => p.name === 'c' && css`
          justify-self: center;
          position: relative;
          @media (min-width: 786px) {
            transform: scale(1.5);
            margin: 80px 0px;
          }
          @media (min-width: 1024px) {
            transform: scale(1);
            margin: 0px;
          }
          * { z-index: 1; }
          &>span {
            position: absolute; left: 107px; top: -10px; z-index: 0;
            width: 2px; height: 100%;
            background-color: #979797;
            @media (min-width: 1024px) {
              left: 244px; top: 0;
            }
          }
        `}
        ${(p) => p.name === 'd' && css`
          display: none;
          @media (min-width: 1024px) {
            display: flex;
            img {
              width: 100%;
            }
          }
        `}
      `,
    },
    graph: {
      component: memo(({ year, arr }) => {
        const { Wrap, Box } = About.history.graph.css;
        return (
          <Wrap>
            <h1>{year}</h1>
            <div>
              {[...arr].reverse().map((item, index) => {
                const key = index;
                return (
                  <Box key={key}>
                    <Box name="a" last={index === 0}>
                      <span />
                    </Box>
                    <Box name="b">
                      <h2>
                        {(item.month < 10 ? '0' : '') + item.month + '월'}
                      </h2>
                    </Box>
                    <Box name="c">
                      {item.text.map((t, tdex) => (
                        <p key={tdex}>{t}</p>
                      ))}
                    </Box>
                  </Box>
                );
              })}
            </div>
          </Wrap>
        );
      }),
      css: {
        Wrap: styled.div`
          display: grid;
          grid-template-columns: 104px auto;
          grid-template-rows: auto;
          @media (min-width: 1024px) {
            grid-template-columns: 240px auto;
            transform: translateY(20px);
            padding: 0px 0px 12px;
          }
          h1 {
            padding: 0px 0px 0px 34px;
            color: #00b4f4;
            font-family: "Noto Sans KR", sans-serif;
            font-size: 20px; font-weight: 400;
            letter-spacing: 0px;
            line-height: 0.6;
            @media (min-width: 1024px) {
              font-size: 54px;
              padding: 0;
            }
          }
        `,
        Box: styled.div`
          ${(p) => !p.name && css`
            display: grid;
            grid-template:
              'a b c' auto / 8px 70px auto
            ;
            @media (min-width: 1024px) {
              grid-template:
                'a b c' auto / 10px 150px auto
              ;
            }
          `}
          ${(p) => p.name && css`
            grid-area: ${p.name};
            min-height: 25px;
            padding: 0px 0px 15px;
            @media (min-width: 1024px) {
              min-height: 65px;
              padding: 0px 0px 40px;
            }
            ${p.name === 'a' && css`
              display: grid;
              justify-content: center;
              span {
                width: 8px; height: 8px;
                border-radius: 50%;
                border: 1px solid #979797;
                background-color: #ffffff;
                @media (min-width: 1024px) {
                  width: 12px; height: 12px;
                }
              }
              ${p.last && css`
                span {
                  width: 10px; height: 4px;
                  border-radius: 2px; border: none;
                  background-color: #207fff;
                  @media (min-width: 1024px) {
                    width: 22px; height: 8px;
                    border-radius: 4px;
                  }
                }
              `}
            `}
            ${p.name === 'b' && css`
              justify-self: center;
              h2 {
                color: #191919);
                font-family: "Noto Sans KR", sans-serif;
                font-size: 12px; font-weight: 400;
                letter-spacing: 0px;
                @media (min-width: 1024px) {
                  font-size: 22px;
                }
              }
            `}
            ${p.name === 'c' && css`
              p {
                color: #191919);
                font-family: "Noto Sans KR", sans-serif;
                font-size: 12px; font-weight: 400;
                letter-spacing: 0px;
                @media (min-width: 1024px) {
                  font-size: 22px;
                }
              }
              &>p:last-child {
                padding: 20px 0px 0px;
              }
              &>p:first-child {
                padding: 0px;
              }
            `}
          `}
        `,
      },
    },
  },
  info: {
    component: memo(() => {
      const { Wrap, Box } = About.info.css;
      return (
        <Wrap>
          <Box name="a">
            <img src={hedyOffice1} alt="hedy office" />
          </Box>
          <Box name="b">
            <img src={hedyOffice2} alt="hedy office" />
          </Box>
          <Box name="c">
            <span />
            {About.data.info.map((item, index) => {
              const key = index;
              return (
                <div key={key}>
                  <h2>{item.name}</h2>
                  <div>
                    {item.text.map((t, tdex) => (
                      <p key={tdex}>{t}</p>
                    ))}
                  </div>
                </div>
              );
            })}
            <span />
          </Box>
          <Box name="d">
            <img src={hedyInfoBg} alt="building" />
          </Box>
        </Wrap>
      );
    }),
    css: {
      Wrap: styled.div`
        display: grid;
        grid-template:
          '. . .' 40px
          '. a .' auto
          '. . .' 10px
          '. b .' auto
          '. . .' 40px
          '. c .' auto
          '. . .' 40px
          'd d d' auto / 25px auto 25px
        ;
        @media (min-width: 1024px) {
          grid-template:
            '. . . .' 97px
            '. a b .' auto
            '. . . .' 60px
            '. c c .' auto
            '. . . .' 28px
            'd d d d' auto / auto calc(997px / 2) calc(997px / 2) auto
          ;
        }
      `,
      Box: styled.div`
        ${(p) => p.name && css`
          grid-area: ${p.name};
          ${p.name === 'a' && css`
            justify-self: center;
            img {
              width: 100%; max-width: 494px;
            }
          `}
          ${p.name === 'b' && css`
            justify-self: center;
            img {
              width: 100%; max-width: 494px;
            }
          `}
          ${p.name === 'c' && css`
            width: 100%; max-width: 494px;
            justify-self: center;
            @media (min-width: 1024px) {
              width: 100%; max-width: 100%;
            }
            &>span {
              display: block;
              width: 100%; height: 3px;
              background-color: #191919;
            }
            &>div {
              position: relative;
              display: grid;
              grid-template-columns: 111px auto;
              grid-template-rows: auto;
              min-height: 30px;
              padding: 8px 0px;
              @media (min-width: 1024px) {
                grid-template-columns: 396px auto;
                min-height: 52px;
                padding: 16px 0px;
              }
              &::before {
                content: '';
                position: absolute; bottom: 0;
                width: 100%; height: 1px;
                background-color: #191919;
              }
              h2 {
                color: #191919;
                font-family: "Noto Sans KR", sans-serif;
                font-size: 12px; font-weight: 700;
                letter-spacing: 0px;
                padding: 0px 0px 0px 25px;
                @media (min-width: 1024px) {
                  font-size: 16px;
                  padding: 0px 0px 0px 83px;
                }
              }
              &>div {
                @media (min-width: 1024px) {
                  &>p:last-child {
                    padding: 7px 0px 0px;
                  }
                  &>p:first-child {
                    padding: 0px;
                  }
                }
                p {
                  color: #191919;
                  font-family: "Noto Sans KR", sans-serif;
                  font-size: 12px; font-weight: 500;
                  letter-spacing: 0px;
                  @media (min-width: 1024px) {
                    font-size: 16px;
                  }
                }
              }
            }
          `}
          ${p.name === 'd' && css`
            img {
              transform: translateY(5px);
              width: 100%;
            }
          `}
        `}
      `,
    },
  },
  data: {
    about: [
      '주식회사 헤디는 mmWave 레이더 센서를 기반으로 산업 안전, 드론 안전/자율비행, 교통/사람 모니터링 등 산업과 일상에서의 안전을 위한 제품 및 기술 적용을 추구합니다.',
      '주식회사 헤디는 레이더센서 제품을 개발하여 드론의 안전/자율비행을 위한 기술에 우선 접목하였고, 지속적인 레이더 비즈니스 발굴과 실증을 통해 지속적인 기술 고도화를 진행하고 있습니다.',
      '주식회사 헤디는 레이더 센서 제품 개발 경험을 바탕으로 관련 사업을 위한 기획부터 고객의 요구사항에 맞게 개발까지 할 수 있는 최고의 파트너 기업이라고 확신합니다.',
    ],
    history: {
      "2019": [
        { year: 2019, month: 8, text: ['법인설립']},
      ],
      "2020": [
        { year: 2020, month: 1, text: ['기업부설연구소 설립'] },
        { year: 2020, month: 5, text: ['벤처기업 인증', '혁신바우쳐사업수행기관 등록'] },
        { year: 2020, month: 6, text: ['RIPS 전문서비스공급기업 등록'] },
        { year: 2020, month: 12, text: ['드론관련특허출원'] },
      ],
      "2021": [
        { year: 2021, month: 5, text: ['창업성장기술개발사업'] },
      ],
      "2022": [
        { year: 2022, month: 5, text: ['벤처기업 갱신'] },
        { year: 2022, month: 12, text: ['드론관련 특허등록'] },
      ],
      "2023": [
        { year: 2023, month: 6, text: ['HRADAR KC 인증'] },
        { year: 2023, month: 9, text: ['창업성장기술개발사업'] },
      ],
    },
    info: [
      { id: 0, name: '기업명', text: ['주식회사 헤디'] },
      { id: 1, name: '대표자', text: ['김 남 수'] },
      { id: 2, name: '설립일자', text: ['2019년 8월'] },
      { id: 3, name: '업종', text: ['무인 항공기 및 무인 비행장치 제조업'] },
      { id: 4, name: '주소', text: ['[본사] 경상남도 창원시 마산회원구 3.15대로 732, 4144호', '[부설연구소] 부산광역시 강서구 명지국제2로 28번길 23 네오웨스턴스퀘어 2동 308호'] },
      { id: 5, name: '전화번호', text: ['051-991-0806'] },
      { id: 6, name: '홈페이지', text: ['hedy.kr'] },
    ],
  },
};
export default About;
