import React, { memo } from 'react'
import styled from 'styled-components'
import { Logo, Menu } from './Default'

const Footer = memo(() => {
  const { WRAP } = CSS
  const list = [
    { title: 'Head Offices', text: '732, 3·15-daero, Masanhoewon-gu, Changwon-si, Republic of Korea' },
    { title: 'R&D Center', text: '2-308, 23, Myeongjigukje 2-ro 28beon-gil, Gangseo-gu, Busan, Republic of Korea' },
    { title: 'Contacts', text: 'hedy@hedy.kr\n+82 51 991 0806' },
  ];
  // 부산광역시 강서구 명지국제2로 28번길 23 네오웨스턴스퀘어 2동 308
  // 23, Myeongjigukje 2-ro 28beon-gil, Gangseo-gu, Busan, Republic of Korea
  return (
    <WRAP>
      <div className={'footer-main'}>
        <div>
          <p>
            Technology for intelligent radar
          </p>
        </div>
        <ul>
        {list.map((l, ldex) => 
          <li key={ldex}>
            <h1>{l.title}</h1>
            <p>{l.text}</p>
          </li>
        )}
        </ul>
      </div>
      <div className={'footer-menu'}>
        <Logo type={'footer'} />
        <Menu type={'button'} />
        <Menu type={'footer'} />
      </div>
    </WRAP>
  )
})

export default Footer

const CSS = {
  WRAP: styled.footer`
    display: flex; flex-direction: column;
    width: 100%;
    background-color: #191919;
    @media (min-width: 1024px) {
      justify-content: center; align-items: center;
      padding: 100px 0px 0px; 
    }

    .footer-main {
      display: flex; flex-wrap: wrap;
      width: 100%;
      padding: 0px 30px;
      ${props => props.theme.media`
        width: 1024px; flex-wrap: initial;
      `}
      * {
        color: #fff;
      }
      &>div {
        flex: 0 auto;
        display: flex;
        width: 100%;
        padding: 30px 0px 15px;
        border-bottom: 1px solid #fff;
        @media (min-width: 1024px) {
          justify-content: center;
          padding: 30px 0px;
          border: none;
        }
        p {
          font-size: 16px; font-weight: 400;
          line-height: 2;
          @media (min-width: 1024px) {
            font-family: "Poppins", sans-serif;
            font-size: 24px; font-weight: 400;
            letter-spacing: 0px;
          }
        }
        /* @media (min-width: 768px) {
          width: 300px; justify-content: flex-start;
        } */
        @media (min-width: 1024px) {
          width: 50%; justify-content: flex-start;
          padding: 0px;
        }
      }
      &>ul {
        flex: 1;
        display: flex; justify-content: space-around;
        padding: 20px 0px;
        @media (min-width: 1024px) {
          justify-content: space-around;
          border-left: 1px solid #fff;
          padding: 0px 0px 0px 50px;
        }
        &>li {
          width: 100px;
          @media (min-width: 1024px) {
            width: 160px;
            margin: 0; padding: 0px 5px;
          }
          * {
            font-family: 'Poppins', sans-serif;
          }
          h1 {
            font-size: 15px; font-weight: bold;
            @media (min-width: 1024px) {
              font-size: 18px;
            }
          }
          p {
            font-size: 12px; font-weight: 400;
            line-height: 1.5;
            opacity: 0.6;
            padding-top: 20px;
            white-space: pre-line;
            @media (min-width: 1024px) {
              font-size: 16px;
            }
          }
        }
      }
    }

    .footer-menu {
      display: flex; justify-content: space-between; align-items: center;
      width: 100%;
      padding: 30px;

      ${props => props.theme.media`
        width: 1024px; padding: 60px 30px;
      `}

      ${props => props.theme.media`
        .hedy-menu-button {
          display: none;
        }
      `}
    }
  `
}

