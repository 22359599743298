import React, { memo, useState, useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'

import logoImage from '../images/logo.png'
import logoImageBlue from '../images/logo-blue.png'
import menu_community from '../images/menu-community.png';
import menu_community_hover from '../images/menu-community-hover.png';

const Default = memo(() => {
  return (
    <div>

    </div>
  )
})

export default Default

export const Logo = memo(({ type }) => {
  const { WRAP } = logo
  return (
    <WRAP types={type ? type : ''}>
      <Link to={'/'}>
        <img 
          src={
            type === 'footer' ? logoImageBlue : logoImage
          }
          alt={'HEDY'} 
        />
      </Link>
    </WRAP>
  )
})
const logo = {
  WRAP: styled.h1`
    transform: translateX(15px);
    display: flex; align-items: center; justify-content: center;
    width: 98px; height: 36px;
    ${props => props.types === 'footer' && `
      width: 96px; height: 33px;
    `}
    a {
      width: 100%; height: 100%;
    }
    img { 
      width: 100%; height: 100%; 
    }
    @media (min-width: 1024px) {
      width: 110px; height: 40px;
      ${props => props.types === 'footer' && `
        width: 107px; height: 38px;
      `}
    }
  `
}

export const Menu = memo(({ type }) => {
  const { WRAP, Button } = menu;
  const [matches, setMatches] = useState(window.matchMedia('(min-width: 1024px)').matches);
  useEffect(() => {
    const resize = () => {
      const m = window.matchMedia('(min-width: 1024px)').matches;
      setMatches(m);
    }
    window.addEventListener('resize', resize);
    return () => window.removeEventListener('resize', resize);
  }, []);
  const buttonClick = function(n) {
    const x = document.querySelector('.hedy-menu')
    if (n === 'opened') return x.style.right = 0
    if (n === 'closed') return x.style.right = `-${1000}px`
  }
  const [menuItems, setMenuItems] = useState([
    { id: 0, name: 'Home', link: '/' },
    { id: 1, name: 'Product', link: '/product' },
    { id: 2, name: 'Service', link: '/service' },
    { id: 3, name: 'Solution', link: '/solution' },
    { id: 4, name: 'About', link: '/about' },
    { id: 5, name: 'Contacts', link: '/contacts' },
    { id: 6, name: 'COMMUNITY', link: '', url: 'https://cafe.naver.com/androiddaq', image: [menu_community, menu_community_hover], hoverSelect: 0, },
  ]);
  const onMenuHover = (event) => {
    const { type } = event;
    setMenuItems((item) => {
      return item.map((i) => {
        if (i.id === 6) {
          return { ...i, hoverSelect: type === 'mouseenter' ? 1 : 0 };
        }
        return i;
      })
    });
  };

  if (type === 'button') {
    return (
      <Button className={'hedy-menu-button'} onClick={() => buttonClick('opened')}>
        <svg width="24" height="24" viewBox="0 0 36 36">
          <path d="M18 0C8.073 0 0 8.073 0 18s8.073 18 18 18 18-8.073 18-18S27.927 0 18 0zm0 2.4c8.63 0 15.6 6.97 15.6 15.6 0 8.63-6.97 15.6-15.6 15.6-8.63 0-15.6-6.97-15.6-15.6C2.4 9.37 9.37 2.4 18 2.4zM10.675 12a1.201 1.201 0 1 0 .125 2.4h14.4a1.2 1.2 0 1 0 0-2.4H10.8a1.168 1.168 0 0 0-.125 0zm0 4.8a1.201 1.201 0 1 0 .125 2.4h14.4a1.2 1.2 0 1 0 0-2.4H10.8a1.167 1.167 0 0 0-.125 0zm0 4.8a1.203 1.203 0 0 0-.742 2.093A1.2 1.2 0 0 0 10.8 24h14.4a1.2 1.2 0 1 0 0-2.4H10.8a1.334 1.334 0 0 0-.125 0z" fill="#FFF" fillRule="nonzero"/>
        </svg>
      </Button>
    )
  }

  return (
    <WRAP className={'hedy-menu'} types={type ? type : ''}>
      <div>
        <button onClick={() => buttonClick('closed')}>
          <span>
            <svg width="30" height="30" viewBox="0 0 20 20">
              <line x1="4" y1="16" x2="16" y2="4" stroke="rgb(50 137 255)" strokeLinecap="round" strokeWidth="3" />
              <line x1="4" y1="4" x2="16" y2="16" stroke="rgb(50 137 255)" strokeLinecap="round" strokeWidth="3" />
            </svg>
          </span>
        </button>
      </div>
      <ul>
        { menuItems.map((i, idex) => {
          if (i.id !== 6) {
            return (
              <li key={idex}>
                <NavLink to={i.link} className={props => props.isActive ? 'active' : undefined}
                  onClick={() => {
                    buttonClick('closed')
                    if (i.url) {
                      window.open(i.url)
                    }
                  }}
                >
                  <span className={'hiddenText'}>{ i.name }</span>
                  <span className={'showText'}>
                    { i.name }
                  </span>
                </NavLink>
              </li>
            );
          }
          return <li key={idex}
            onMouseEnter={onMenuHover}
            onMouseLeave={onMenuHover}
          >
            <div className={'imageLink'}
              style={{
                cursor: 'pointer'
              }}
              onClick={() => {
                buttonClick('closed')
                if (i.url) {
                  window.open(i.url)
                }
              }}
            >
              <span className={'hiddenText'}>{ i.name }</span>
              <span className={'showText'}>
                { i.image && matches ? <img src={i.image[i.hoverSelect]} alt="" /> : i.name }
              </span>
            </div>
          </li>;
        }) }
      </ul>
    </WRAP>
  )
})
const menu = {
  WRAP: styled.nav`
    position: fixed; right: -200%; top: 0; z-index: 99999;
    width: 375px; height: 100%;
    padding: 15px;
    border-radius: 3px;
    box-shadow: -8px 0 24px 0 rgba(0, 0, 0, 0.5);
    background-color: #fff;
    transition: right 0.5s;

    ${props => props.theme.media`
      position: relative; right: 0px !important;
      display: flex; align-items: center;
      padding: 0px;
      box-shadow: none;
      background: none;
    `}

    &>div {
      width: 100%; height: 60px;
      ${props => props.theme.media`display: none;`}
      button {
        display: flex; justify-content: center; align-items: center;
        width: 60px; height: 60px;
        span {
          display: flex; justify-content: center; align-items: center;
          width: 48px; height: 48px;
          font-size: 48px; font-family: 'poppins', sans-serif;
          color: rgb(50 137 255);
          border-radius: 10px; border: 4px solid rgb(50 137 255);
        }
      }
    }
    &>ul {
      width: 100%; height: calc(100% - 60px);
      padding: 10px 25px;
      overflow: scroll;
      ${props => props.theme.media`
        display: flex; justify-content: flex-end; align-items: center;
        padding: 0;
        overflow: visible;
      `}
      li {
        display: flex; align-items: center;
        width: 100%; height: 70px;
        ${props => props.theme.media`
          width: auto; height: 25px;
          padding: 0px 15px;
          .active {
            
          }
        `}
        a, .imageLink {
          position: relative;
          display: flex; align-items: center;
          width: 100%; height: 100%;
          font-size: 48px; font-weight: 400;
          font-family: 'poppins', sans-serif;
          color: rgb(50 137 255);
          &>span {
            position: relative;
            font-size: 48px; font-weight: 400;
            font-family: 'poppins', sans-serif;
            cursor: pointer;
          }
          .hiddenText {
            color: rgba(0, 0, 0, 0);
          }
          .showText {
            position: absolute; left: 5px;
          }
          &:hover {
            .showText {
              font-weight: 600;
            }
          }
          ${props => props.theme.media`
            &>span {
              font-size: 18px;
            }
            .showText {
              left: 50%; transform: translateX(-50%);
              color: #fff !important;
            }
          `}
        }
        &:last-child {
          a, .imageLink {
            height: 60px;
            color: #fff !important;
            padding: 0px 10px;
            border-radius: 8px;
            background: linear-gradient(298deg, rgba(3,254,74,1) 0%, rgba(0,181,255,1) 100%);
            &>span { font-size: 45px; }
          }
          ${props => props.theme.media`
            padding: 0px;
            a, .imageLink {
              height: 100%;
              background: none;
              ${props.types === 'footer' && ``}
              &>span { font-size: 18px; }
              .hiddenText {
                color: rgba(0,0,0,0);
                background: none;
              }
              .showText {
                ${props.types === 'footer' && `color: #191919 !important;`}
              }
            }
          `}
        }
      }
    }
  `,
  Button: styled.button`
    width: 24px; height: 24px;
    @media (min-width: 1024px) {
      display: none;
    }
  `,
}

export const Title = memo(({ pathname }) => {
  const { WRAP } = title
  const items = [
    { id: 0, label: null, title: null, text: ['차별화된 솔루션을 제공하고 새로운 성장을 위해 노력합니다.', 'Get involved.', 'Try everything.'], link: '/' },
    { id: 1, label: '제 품 소 개', title: 'Product', text: ['고도화된 지능형 레이더를 위해 헤디는 최고의 기술과 창의력을', '바탕으로 개발합니다'], link: '/product' },
    { id: 2, label: '헤디 서비스', title: 'HEDY SERVICE', text: ['헤디는 레이더 사업을 위한 기획부터 고객의 요구사항에 맞춘 개발까지', '최고의 파트너 기업으로 도약을 향해 나아갑니다.'], link: '/service' },
    { id: 3, label: '헤디만의 차별화된 솔루션', title: 'SOLUTION', text: ['레이더 및 드론 비즈니스 발굴과 실증을 통해', '지속적인 기술 고도화를 진행하고 있습니다'], link: '/solution' },
    { id: 4, label: '회사개요', title: 'About Us', text: ['주식회사 헤디의 기술개발과 도전정신으로', '레이더 비지니스 분야의 선도기업으로 성장합니다.'], link: '/about' },
    { id: 5, label: '고 객 지 원', title: 'Contact us', text: ['궁금하신 사항이 있으시다면 정성을 다해 도와드리겠습니다.', '항상 고객의 입장에서 끊임없이 연구하는 주식회사 헤디가 되겠습니다.'], link: '/contacts' },
    { id: 6, label: '', text: [], link: '' },
  ]

  return (
    <WRAP pathname={pathname}>
      {items.map((i, idex) => 
        i.link === pathname && <div key={idex}>
          {i.label && <label>{i.label}</label>}
          {i.title && <h2>{i.title}</h2>}
          {i.text && i.text.map((t, tdex) => <p key={tdex}>{ t }</p>)}
        </div>
      )}
    </WRAP>
  )
})
const title = {
  WRAP: styled.h1`
    color: #fff;
    ${props => props.pathname === '/' && css`
      align-self: flex-end;
      &>div {
        transform: translateX(-1px);
        p {
          font-size: 36px; font-weight: 900;
          font-family: 'Poppins', sans-serif;
          @media (min-width: 400px) { font-size: 40px; }
          ${props => props.theme.media`font-size: 80px;`}
        }
        p:nth-child(1) {
          font-size: 12px; font-weight: 700;
          font-family: 'Noto Sans KR', sans-serif;
          margin: 0px 0px 15px;
          @media (min-width: 400px) { font-size: 12px; }
          ${props => props.theme.media`font-size: 24px; margin: 0px 0px 28px;`}
        }
        p:nth-child(2), p:nth-child(3) {
          font-style: italic;
          padding: 3px 0px;
        }
      }
    `}
    ${props => [
      '/product', '/service', '/solution', '/about', '/contacts'
    ].includes(props.pathname) && css`
      display: block;
      text-align: center;
      margin: 60px auto;
      ${props => props.pathname === '/product' && 'margin: 100px auto;'}
      ${props => props.pathname === '/service' && props.theme.media`margin: 140px auto;`}
      ${props => props.pathname === '/solution' && `margin: 110px auto 0px;`}
      ${props => props.pathname === '/about' && props.theme.media`margin: 90px auto;`}
      ${props => props.pathname === '/contacts' && props.theme.media`
        margin: 170px auto 0px;
      `}
      &>div {
        * + * {
          margin: 10px 0px 0px 0px;
        }
        label {
          font-size: 16px; font-weight: 300;
          font-family: 'Noto Sans KR', sans-serif;
          ${props => props.theme.media`font-size: 36px;`}
        }
        h2 {
          font-size: 36px; font-weight: 300;
          font-family: 'Poppins', sans-serif;
          margin: 20px 0px;
          ${props => props.theme.media`font-size: 88px; margin-bottom: 60px;
            ${props.pathname === '/product' && `margin: 10px 0px 40px;`}
            ${props.pathname === '/service' && `margin: 30px 0px 55px;`}
            ${props.pathname === '/solution' && `margin: 40px 0px 60px;`}
            ${props.pathname === '/about' && `margin-bottom: 80px;`}
          `}
        }
        p {
          font-size: 10px; font-weight: 300;
          font-family: 'Noto Sans KR', sans-serif;
          ${props => props.theme.media`font-size: 16px;`}
        }
      }
    `}
  `
}