import { css } from "styled-components"

const size = {
  media: 1024,
}

const media = Object.keys(size).reduce((acc, label) => {
  acc[label] = ( ...args ) => css`
    @media (min-width: ${size[label]}px) {
      ${css( ...args )};
    }
  `
  return acc
}, {})

export default media