/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';

import { useLocation, useNavigate } from 'react-router-dom';

import solution_as from './images/solution-as.png';
import solution_fc from './images/solution-fc.png';
import solution_pd from './images/solution-pd.png';
import solution_ls from './images/solution-ls.png';
import solution_hc from './images/solution-hc.png';
import solution_tm from './images/solution-tm.png';

const Solution = {
  index: {
    component: memo(() => {
      const { Wrap, Box } = Solution.index.css;
      const navigate = useNavigate();
      const location = useLocation();
      const wrapRef = useRef(null);
      const ref = {
        as: useRef(null),
        fc: useRef(null),
        pd: useRef(null),
        ls: useRef(null),
        hc: useRef(null),
        tm: useRef(null),
      };
      useEffect(() => {
        window.scrollTo(0, 0);
        if (location.hash !== '') {
          const target = ref[location.hash.replace('#', '')];
          if (target) {
            target.current.scrollIntoView({ behavior: 'smooth' });
          }
        }
        navigate(location.pathname, { replace: true })
      }, []);
      
      return (
        <Wrap ref={wrapRef}>
          {Solution.data.map((item, index) => {
            const key = `solution${index}`;
            return (
              <React.Fragment key={key}>
                <Box ref={ref[item.ref]}>
                  <Box name="a">
                    <h2>{item.alt}</h2>
                  </Box>
                  <Box name="b">
                    <h1>{item.title}</h1>
                  </Box>
                  <Box name="c">
                    {item.text.map((t, tdex) => (
                      <p key={tdex}>{t}</p>
                    ))}
                  </Box>
                  <Box name="d">
                    <img src={item.src} alt={item.alt} />
                  </Box>
                </Box>
              </React.Fragment>
            );
          })}
        </Wrap>
      );
    }),
    css: {
      Wrap: styled.div`
        width: 100%; max-width: 100%;
        @media (min-width: 1024px) {
          padding: 75px 0px;
        }
      `,
      Box: styled.div`
        ${(p) => !p.name && css`
          display: grid;
          grid-template:
            '. . .' 40px
            '. a .' 32px
            '. b .' 32px
            '. c .' auto
            '. d .' auto
            '. . .' 40px / 25px auto 25px
          ;
          @media (min-width: 1024px) {
            grid-template-areas:
              '. . .'
              '. a .'
              '. b .'
              '. c .'
              '. d .'
            ;
            grid-template-columns: auto 1000px auto;
            grid-template-rows: 80px 43px 43px auto 563px;
          }
        `}
        
        ${(p) => p.name ? css`
          grid-area: ${p.name};
          @media (min-width: 1024px) {
            justify-self: start;
            width: 100%;
          }
          &>* {
            word-break: keep-all;
          }
          &>h1 {
            color: #000000;
            font-family: "Noto Sans KR", sans-serif;
            font-size: 20px; font-weight: 400;
            letter-spacing: 0px;
            @media (min-width: 1024px) {
              font-size: 30px;
            }
          }
          &>h2 {
            color: #000000;
            font-family: "Noto Sans KR", sans-serif;
            font-size: 20px; font-weight: 700;
            letter-spacing: 0px;
            @media (min-width: 1024px) {
              font-size: 30px;
            }
          }
          &>p {
            color: #000000;
            font-family: "Noto Sans KR", sans-serif;
            font-size: 12px; font-weight: 400;
            letter-spacing: 0px;
            line-height: 18px;
            @media (min-width: 1024px) {
              font-size: 18px; font-weight: 300;
            }
          }
          &>img {
            width: 100%;
            @media (min-width: 1024px) {
              width: 100%; height: 500px;
            }
          }
          ${p.name === 'a' && css`
            @media (min-width: 1024px) {

            }
          `}
          ${p.name === 'b' && css`
            @media (min-width: 1024px) {
              display: flex; align-items: center;
            }
          `}
          ${p.name === 'c' && css`
            padding: 0px 0px 20px;
            @media (min-width: 1024px) {
              padding: 10px 0px 0px;
            }
          `}
          ${p.name === 'd' && css`
            display: flex;
            justify-content: center; align-items: center;
          `}
        ` : ''}
      `,
    },
  },
  data: [
    // src: { main: [ M, D ], page [ M ,D ] }
    {
      id: 1,
      ref: 'as',
      src: solution_as,
      alt: 'Area Sensing(AS)',
      title: '다목적 검출 범용',
      text: [
        '감지되는 Point Cloud 정보(x,y,z,v)를 출력하며 다양한 분야에 적용할 수 있는 기본 Solution입니다.',
        'Point Cloud 정보를 획득하여 원하는 Application에 적용 가능합니다.',
      ],
    },
    {
      id: 2,
      ref: 'fc',
      src: solution_fc,
      alt: 'Flight Controller(FC)',
      title: '드론 충돌방지 및 회피',
      text: [
        '드론에 적용해 충돌방지 및 회피 기능 제공하는 Solution입니다.',
        'MAVLink Protocol을 지원하기 때문에 Flight Controller(FC)에 연결해 즉시 사용 가능합니다.'
      ],
    },
    {
      id: 3,
      ref: 'pd',
      src: solution_pd,
      alt: 'People Detecting(PD)',
      title: '실외/실내 사람 감지',
      text: [
        '사람을 감지하고 추적하는 Solution입니다.',
        '감지되는 Point Cloud 정보를 기반으로 Target Object(사람) 할당 및 추적정보를 제공합니다.',
      ],
    },
    {
      id: 4,
      ref: 'ls',
      src: solution_ls,
      alt: 'Level Sensing(LS)',
      title: '유체/고체 레벨 측정',
      text: [
        '고체 또는 유체의 Level을 감지하는 Solution입니다.',
        '비접촉식으로 타겟의 Level정보를 1mm 정밀도로 제공합니다.',
      ],
    },
    {
      id: 5,
      ref: 'hc',
      src: solution_hc,
      alt: 'Health Care',
      title: '실내 사람 안전 모니터링',
      text: [
        '사람을 감지하고 호흡 및 심박수를 측정하는 Solution입니다. 실내 재실 유무 및 위치, 건강 상태를 확인할 수 있습니다.',
      ],
    },
    {
      id: 6,
      ref: 'tm',
      src: solution_tm,
      alt: 'Traffic Monitoring',
      title: '교통/보행 모니터링',
      text: [
        '도로 또는 인도의 차량 및 사람을 감지하는 Solution입니다. 차량 또는 사람의 통행량, 위치를 확인할 수 있습니다.',
      ],
    },
  ],
};
export default Solution;